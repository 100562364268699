<template>
  <div class="tariffs-table">
    <base-table
      :columns="tableHead"
      :list="paginList"
      :sort="sort"
      class="tariffs-table__table"
      @sort-change="onSortChange"
    >
      <tariffs-table-row
        v-for="item in paginList"
        :key="item.id"
        :columns="tableHead"
        :item="item"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <new-pagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableRow from './TariffsTableRow';
import NewPagination from '@/components/Pagination/NewPagination';
import pagination from '@/mixins/pagination';
export default {
  name: 'TariffsTableClouds',
  components: { BaseTable, TariffsTableRow, NewPagination },
  mixins: [pagination],
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'server',
          label: this.$t('tableHead.server'),
          style: {
            //flex: '1 1 auto',
            width: '230px',
            maxWidth: '230px',
          },
          sort: {
            prop: 'title',
            order: 'asc',
          },
        },
        {
          key: 'id',
          label: this.$t('tableHead.id'),
          style: {
            width: '80px',
          },
          sort: {
            prop: 'id',
            order: 'asc',
          },
        },
        {
          key: 'datacentername',
          label: this.$t('tableHead.datacentername'),
          style: {
            width: '134px',
          },
        },
        {
          key: 'expiredate',
          label: this.$t('tableHead.expiredate'),
          style: {
            width: '134px',
          },
          sort: {
            prop: 'expiredate',
            order: 'asc',
          },
        },
        {
          key: 'period',
          label: this.$t('tableHead.period'),
          style: {
            width: '134px',
          },
        },
        {
          key: 'autoprolong',
          style: {
            width: '44px',
          },
        },
        {
          key: 'cost',
          label: this.$t('tableHead.cost'),
          style: {
            width: '90px',
          },
          sort: {
            prop: 'cost',
            order: 'asc',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '106px',
          },
          sort: {
            prop: 'status',
            order: 'asc',
          },
        },
        {
          key: 'more',
          style: {
            width: '44px',
          },
        },
      ],
      //stepOptions: [10, 25, 50, 100, 'all'],
      /*pagin: {
        start: 0,
        step: 10,
      },*/
    };
  },
  computed: {
    list() {
      return this.$store.getters['moduleMo365/GET_SORTED_LIST'];
    },
    sort() {
      return this.$store.state.moduleMo365.sort;
    },
    /*paginList() {
      if (this.pagin.step === 'all') return this.list;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.list.slice(start, end);
    },*/
  },
  watch: {
    sort: {
      handler(val /*old*/) {
        this.updateSort(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSort(this.sort);
  },
  methods: {
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit('moduleMo365/SET_SORT', { prop, order });
    },
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    /*setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },*/
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "server": "Тариф",
      "expiredate": "Действует до",
      "active": "Активен",
      "id": "ID",
      "datacentername": "Дата-центр",
      "period": "Период",
      "mem": "RAM",
      "disc": "HDD",
      "cost": "Цена",
      "status": "Статус"
    },
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": {
      "action": "Продлить",
      "disabled": "Выбраны услуги, для которых продление недоступно",
      "title": "Продление тарифов",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифов {msg} успешно добавлено в корзину.",
      "success_order": "Продление тарифов {msg} прошло успешно. Данные скоро обновятся.",
      "error_result": "Продление тарифов {msg} не удалось.",
      "confirm": "Оплатить",
      "order": "В корзину"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
